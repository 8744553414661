<template>
    <div class="Coursedetails clearfix">
        <div class="coudetail_l fl">
            <div class="active_div" v-preventReClick @click="back()">
                <i class="iconfonts icons-shouyefill"></i>
                <span>返回首页</span>
            </div>
            <ul class="coude_ul">
                <li :class="$store.state.tem_id==item.id?'active_li':''" v-for="(item,index) in teamList" :key="index" v-preventReClick @click="teamBox(item)">
                     <p class="team">{{item.name}}</p>
                     <p class="total">小组总人数：{{item.unit}}</p>
                     <p class="total">已加入人数：{{item.users}}</p>
                </li>
            </ul>
        </div>
        <div class="coudetail_r fl">
            <div class="cou_selp_div">
                <ul class="step_div_steps">
                    <li v-for="(item,index) in form.steps" :key="index">
                        <div class="bor_divsss" :class="item.state!=0?'actice_div':''">
                            <div class="bor_div_top">
                                <el-progress type="circle" 
                                    :percentage="item.state!=0 && item.id == 1?16.7:item.state!=0 && item.id == 2?33.4:item.state!=0 && item.id == 3?50.1:item.state!=0 && item.id == 4?66.8:item.state!=0 && item.id == 5?83.5:item.state!=0 && item.id == 6?100:0" 
                                    :stroke-width="8" 
                                    :show-text='false' 
                                    :color="'#54A9D8'"
                                ></el-progress>
                                <i class="iconfonts" :class="item.icon"></i>
                            </div>
                            <div class="bor_div_title" :class="active==item.id?'acti_title':''">
                                {{item.name}}
                            </div>
                        </div>
                        <div class="bor_div_r" :class="item.state!=0?'acti_div_r':''" v-show="index!= form.steps.length - 1">
                            <div class="one_r">

                            </div>
                            <div class="two_r">

                            </div>
                            <div class="two_rs">

                            </div>
                            <div class="three_r">
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="cou_title ">
                <div class="cou_title_div clearfix">
                    <!-- <div class="back_div fl" v-preventReClick @click="back()">
                        <i class="el-icon-arrow-left"></i>
                        <span>返回</span>
                    </div> -->
                    <button class="fl _tit_button" v-show="form.id==0" v-preventReClick @click="classes()">
                        开课
                    </button>
                    <button class="fl _tit_button" v-show="form.id==99">
                        已结课
                    </button>
                    <button class="fl _tit_button" v-show="form.id!=0 && form.id<=8" v-preventReClick @click="nextes()">
                        开启下一步：{{form.next}}
                    </button>
                    <el-button type="success" size="mini" class="fl" v-preventReClick @click="dialogVisible = true" v-show="countNum!=1 || count.state!=0">
                        新建倒计时
                    </el-button>
                    <el-button type="success" size="mini" class="fl" v-preventReClick @click="startdown()" v-show="countNum==1 && count.state==0">
                        开始倒计时
                    </el-button>
                    <div class="count_span fl" v-show="countNum==1">
                        <span>{{count.name}}倒计时：</span>
                        <span class="timer_s">{{timer}}</span>
                    </div>
                    <!-- <el-button type="info" size="mini" class="fr" v-show="form.id==99" v-preventReClick @click="Allexport()">
                        全部导出
                    </el-button> -->
                    <!-- <el-button type="primary" class="fr _tit_button" style="margin-left:15px" v-show="form.id!=0" v-preventReClick @click="statistics()">
                        课程统计
                    </el-button> -->
                    <el-button type="danger" size="mini" class="fr" v-show="form.id!==0 && form.id!==99" v-preventReClick @click="session()">
                        结课
                    </el-button>
                </div>
            </div>
            <div class="content_diov">
                <ul class="content_diov_top">
                    <li >
                        <img src="../../assets/img/s1.png" alt="">
                        <div class="li_div_d">
                            <p> <span>①</span>目标用户</p>
                            <button v-preventReClick @click="aaaaa(paperList.s1)" v-show="paperList.s1.value==1">查看详情</button>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/img/s2.png" alt="">
                        <div class="li_div_d">
                            <p> <span>②</span>目标客户</p>
                            <button v-preventReClick @click="aaaaa(paperList.s2)" v-show="paperList.s2.value==1">查看详情</button>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/img/s3.png" alt="">
                        <div class="li_div_d">
                            <p> <span>③</span>用户应用场景</p>
                            <button v-preventReClick @click="aaaaa(paperList.s3)" v-show="paperList.s3.value==1">查看详情</button>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/img/s4.png" alt="">
                        <div class="li_div_d">
                            <p> <span>④</span>客户购买场景</p>
                            <button v-preventReClick @click="aaaaa(paperList.s4)" v-show="paperList.s4.value==1">查看详情</button>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/img/s5.png" alt="">
                        <div class="li_div_d">
                            <p> <span>⑤</span>竞品分析</p>
                            <button v-preventReClick @click="aaaaa(paperList.s5)" v-show="paperList.s5.value==1">查看详情</button>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/img/s6.png" alt="">
                        <div class="li_div_d">
                            <p> <span>⑥</span>产品规划</p>
                            <button v-preventReClick @click="aaaaa(paperList.s6)" v-show="paperList.s6.value==1">查看详情</button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="course_popup">
            <el-dialog
                title="新建倒计时"
                :visible.sync="dialogVisible"
                :show-close="false"
                width="450px"
                :before-close="handleClose">
                    <i class="el-icon-close closei" v-preventReClick @click="closei('ruleForm')"></i>
                    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="70px" size="small" @submit.native.prevent @keyup.enter.native="enterInput($event)">
                        <el-form-item label="分钟数" prop="mins">
                            <el-input v-model.number="ruleForm.mins" placeholder="请输入分钟数"></el-input>
                        </el-form-item>
                        <el-form-item label="类型" prop="type">
                            <el-radio-group v-model="ruleForm.type">
                                <el-radio :label="1">直接开始倒计时</el-radio>
                                <el-radio :label="0">暂存</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button size="small" v-preventReClick @click="closei('ruleForm')">取 消</el-button>
                        <el-button size="small" type="success" v-preventReClick @click="addPro('ruleForm')">确 定</el-button>
                    </span>
            </el-dialog>
            <el-dialog
                title="教师点评"
                :visible.sync="dialogVisibleForm"
                class="DP_teace"
                :show-close="false"
                width="750px"
                :before-close="handleClose">
                <i class="el-icon-close closei" v-preventReClick @click="closei('infoForm')"></i>
                <el-form ref="infoForm" :model="infoForm" :rules="rules" size="small" label-width="120px" :inline='true' @submit.native.prevent>
                    <el-form-item label="课堂表现积极性" prop="top"
                        :rules="[{ required: true, message: '请选择表现最积极的小组', trigger: 'change' }]">
                        <el-select v-model="infoForm.top" placeholder="请选择小组">
                            <el-option
                                v-for="item in teamList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="作业完成度" prop="pos"
                        :rules="[{ required: true, message: '请选择作业完成度最好的小组', trigger: 'change' }]">
                        <el-select v-model="infoForm.pos" placeholder="请选择小组">
                            <el-option
                                v-for="items in teamList"
                                :key="items.id"
                                :label="items.name"
                                :value="items.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-for="(citem,cindex) in infoForm.score" :key="cindex" class="aaa_form">
                        <el-form-item :label="citem.name" :prop="`score.${cindex}.val`"
                            :rules="[{ required: true, message: '请填写分数值', trigger: 'blur' },
                                        {pattern: /^([1-9]?\d|100)$$/,message:'分值必须是0-100的整数'},
                                    ]">
                            <el-input  v-model="citem.val" placeholder="填写分数" ></el-input>
                        </el-form-item>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" v-preventReClick @click="closei('infoForm')">取消</el-button>
                    <el-button type="primary" size="mini" v-preventReClick @click="subxits('infoForm')">确定</el-button>
                </span>
            </el-dialog>
            <el-dialog
                :visible.sync="dialogPrint"
                class="dia_print"
                :show-close="false"
                width="85%"
                :before-close="handleClose"
            >
                <i class="el-icon-close closeis" v-preventReClick @click="closeis()"></i>
                <div class="tab_boxs">
                    
                    <span>
                        打印预览（A4横版）
                        <span>
                            提示：布局为横向、纸张大小为A4、边距为无
                        </span>
                    </span>
                </div>
                <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                    <el-tab-pane :label="item.title" :name="item.title" v-for="(item,index) in sortList" :key="index">
                        <div class="tab_boxs_div">
                            <el-button type="primary" v-print="'#print'+index">导出第{{index+1}}组报告</el-button>
                        </div>
                        <div class="print_div">
                            <div :id="'print'+index">
                                <!-- 总体报告 -->
                                <p class="print_allPs"></p>
                                <div class="print_main_div print_overall">
                                    <div class="print_left">
                                        <div class="print_left_top">
                                            <p class="clearfix print_l_one">
                                                <span class="fl print__top_one">
                                                    <i class="iconfonts icons-banji"></i>
                                                </span>
                                                <span class="fl print_top_text">
                                                    开班情况
                                                </span>
                                            </p>
                                            <ul class="pring_ul">
                                                <li class="pring_ul_one">
                                                    <span>开课报告</span>
                                                </li>
                                                <li class="pring_ul_two clearfix">
                                                    <span class="fl _ul_two_l">
                                                        实训名称
                                                    </span>
                                                    <span class="fr _ul_two_r">
                                                        {{item.page0.course.name}}
                                                    </span>
                                                </li>
                                                <li class="pring_ul_two clearfix">
                                                    <span class="fl _ul_two_l">
                                                        班级
                                                    </span>
                                                    <span class="fr _ul_two_r">
                                                        {{item.page0.course.grade}}
                                                    </span>
                                                </li>
                                                <li class="pring_ul_two clearfix">
                                                    <span class="fl _ul_two_l">
                                                        开课时间
                                                    </span>
                                                    <span class="fr _ul_two_r">
                                                        {{item.page0.course.start}}
                                                    </span>
                                                </li>
                                                <li class="pring_ul_two clearfix">
                                                    <span class="fl _ul_two_l">
                                                        实训时间
                                                    </span>
                                                    <span class="fr _ul_two_r">
                                                        {{item.page0.course.start}} ~ {{item.page0.course.end}}
                                                    </span>
                                                </li>
                                                <li class="pring_ul_two clearfix">
                                                    <span class="fl _ul_two_l">
                                                        参加人数
                                                    </span>
                                                    <span class="fr _ul_two_r">
                                                        {{item.page0.course.users}}人
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="print_left_bott">
                                            <div class="review_top">
                                                <p class="p_list_p">组员名单</p>
                                                <ul class="p_list_ul">
                                                    <li class="p_list_ul_li" v-for="(items,indexs) in item.page0.team.users" :key="indexs">
                                                        <span>{{items.name}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="review_dib">
                                                <p class="clearfix print_l_one">
                                                    <span class="fl print__top_four">
                                                        <i class="iconfonts icons-dianping"></i>
                                                    </span>
                                                    <span class="fl print_top_text">
                                                        教师点评
                                                    </span>
                                                </p>
                                                <div class="p_bott_div">
                                                    <p class="p_bott_div_p clearfix">
                                                        <span class="p_bott_div_sp_l fl">
                                                            课堂表现积极性：
                                                        </span>
                                                        <span class="p_bott_div_sp_r fl">
                                                            {{item.page0.score.top}}
                                                        </span>
                                                    </p>
                                                    <p class="p_bott_div_p clearfix">
                                                        <span class="p_bott_div_sp_l fl">
                                                            作业完成度：
                                                        </span>
                                                        <span class="p_bott_div_sp_r fl">
                                                            {{item.page0.score.pos}}
                                                        </span>
                                                    </p>
                                                    <p class="p_bott_div_p clearfix" style="margin-bottom:0">
                                                        <span class="p_bott_div_sp_l fl">
                                                            本组作品得分：
                                                        </span>
                                                        <span class="p_bott__ul fl">
                                                            <span>{{item.page0.score.score}}</span>分
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="print_right">
                                        <div class="print_right_top">
                                            <p class="clearfix p_l_r_top">
                                                <span class="fl p_l_top_two">
                                                    <i class="iconfonts icons-pingfen1"></i>
                                                </span>
                                                <span class="fl print_top_text">
                                                    学生评比
                                                </span>
                                            </p>
                                            <div class="bingz_tu fl">
                                                <img src="" :id="'main'+index" style="width:100%;height:100%;">
                                            </div>
                                            <div class="zhuz_tu fr">
                                                <img src="" :id="'mains'+index" style="width:100%;height:100%;">
                                            </div>
                                        </div>
                                        <div class="print_right_bott">
                                            <p class="clearfix print_l_one">
                                                <span class="fl print__top_three">
                                                    <i class="iconfonts icons-shujufenxi"></i>
                                                </span>
                                                <span class="fl print_top_text">
                                                    课程数据
                                                </span>
                                            </p>
                                            <div class="p_data_div clearfix">
                                                <div class="p_data_div_l fl">
                                                    <div class="caotu_p_div">
                                                        <p class="p_le_ps">草图</p>
                                                        <div class="p_le_imgs">
                                                            <img :src="item.page0.s9.p1.value" alt="">
                                                        </div>
                                                    </div>
                                                    <div class="caotu_p_div">
                                                        <p class="p_le_ps">推演测试</p>
                                                        <div class="p_le_imgss_t">
                                                            <img :src="item.page0.s9.p8.value" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p_data_div_r fr">
                                                    <el-form :model="courForm"  class="demo-ruleForm" label-width="96px" size="small" @submit.native.prevent>
                                                        <el-form-item label="现状：" prop="name">
                                                            <el-col :span="24">
                                                                <div class="p_col_div">
                                                                    {{item.page0.s9.p2.value}}
                                                                </div>
                                                            </el-col>
                                                        </el-form-item>
                                                        <el-form-item label="问题：" prop="name">
                                                            <el-col :span="24">
                                                                <div class="p_col_div">
                                                                    {{item.page0.s9.p3.value}}
                                                                </div>
                                                            </el-col>
                                                        </el-form-item>
                                                        <el-form-item label="创意（想法）：" prop="name">
                                                            <el-col :span="24">
                                                                <div class="p_col_div">
                                                                    {{item.page0.s9.p4.value}}
                                                                </div>
                                                            </el-col>
                                                        </el-form-item>
                                                        <el-form-item label="创新（想法）：" prop="name">
                                                            <el-col :span="24">
                                                                <div class="p_col_div">
                                                                    <div class="p_zui_three_ul">
                                                                        <div class="p_zui_three_ul_li" v-for="(citem,cindex) in item.page0.s9.p5.value" :key="cindex">
                                                                            <div class="p_zui_three_div clearfix">   
                                                                                <span class="fl">热度为{{citem.level}}颗星的：</span>
                                                                                <span class="fl">
                                                                                    <el-rate
                                                                                        v-model="citem.level"
                                                                                        disabled
                                                                                        :colors="['#ff9900','#ff9900','#ff9900']">
                                                                                    </el-rate>
                                                                                </span>
                                                                            </div>
                                                                            <div class="p_icon_ul clearfix">
                                                                                <div class="p_zui_ul_li_item fl" v-for="(items3,indesx3) in citem.values" :key="indesx3">
                                                                                    {{items3.name}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </el-col>
                                                        </el-form-item>
                                                        <el-form-item label="方案（适应）：" prop="name">
                                                            <el-col :span="24">
                                                                <div class="p_col_div">
                                                                    {{item.page0.s9.p6.value}}
                                                                </div>
                                                            </el-col>
                                                        </el-form-item>
                                                    </el-form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="print_allPs"></p>
                                <div class="print_main_div">
                                    <div class="print_RZSL print_one">
                                        <p class="print_allP">认知梳理</p>
                                        <div class="print_rz">
                                            <div class="print_goodat">
                                                <span>团队潜在机会</span>
                                            </div>
                                            <div class="print_good">
                                                <div class="p_zui_three_ul">
                                                    <div class="p_zui_three_ul_li" v-for="(item1,index1) in item.page1.data" :key="index1">
                                                        <div class="p_zui_three_div clearfix">   
                                                            <span class="fl">热度为{{item1.level}}颗星的：</span>
                                                            <span class="fl">
                                                                <el-rate
                                                                    v-model="item1.level"
                                                                    disabled
                                                                    :colors="['#ff9900','#ff9900','#ff9900']">
                                                                </el-rate>
                                                            </span>
                                                        </div>
                                                        <div class="p_icon_ul clearfix">
                                                            <div class="p_zui_ul_li_item fl" v-for="(item1s,indes3s) in item1.values" :key="indes3s">
                                                                {{item1s.name}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="print_allPs"></p>
                                <div class="print_main_div print_one">
                                    <div class="print_ZLSJ">
                                        <p class="print_allP">资料收集</p>
                                        <div class="print_input">
                                            <span class="print_input_span">主营的一个产品（或服务）是：{{item.page2.data.p1.value}}</span>
                                        </div>
                                        <div class="print_content clearfix">
                                            <div class="prin_colle_div fl">
                                                <div class="prin_back_one">
                                                    <div>
                                                        <span class="prin_colle_div_span">它的用户主要是</span>
                                                    </div>
                                                </div>
                                                <ul class="prin_b_ul">
                                                    <li v-for="(itemT1,indexT1) in item.page2.data.p2.values" :key="indexT1">
                                                        {{indexT1+1}}、{{itemT1.value}}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="prin_colle_div fl">
                                                <div class="prin_back_two">
                                                    <div>
                                                        <span class="prin_colle_div_span">它的买点是</span>
                                                    </div>
                                                </div>
                                                <ul class="prin_b_ul">
                                                    <li v-for="(itemT2,indexT2) in item.page2.data.p3.values" :key="indexT2">
                                                        {{indexT2+1}}、{{itemT2.value}}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="prin_colle_div fl">
                                                <div class="prin_back_three">
                                                    <div>
                                                        <span class="prin_colle_div_span">价格的定位是</span>
                                                    </div>
                                                </div>
                                                <ul class="prin_b_ul">
                                                    <li v-for="(itemT3,indexT3) in item.page2.data.p4.values" :key="indexT3">
                                                        {{indexT3+1}}、{{itemT3.value}}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="prin_colle_div fl">
                                                <div class="prin_back_four">
                                                    <div>
                                                        <span class="prin_colle_div_span">它很吸引我的地方是</span>
                                                    </div>
                                                </div>
                                                <ul class="prin_b_ul">
                                                    <li v-for="(itemT4,indexT4) in item.page2.data.p5.values" :key="indexT4">
                                                        {{indexT4+1}}、{{itemT4.value}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="print_allPs"></p>
                                <div class="print_main_div print_one">
                                    <div class="print_CGJD">
                                        <p class="print_allP">重构焦点</p>
                                        <ul class="clearfix p_kehu_ul">
                                            <li class="fl p_kehu_ul_li">
                                                <div class="print_li_one marginB30">
                                                    <div class="orin_ul_li_div">
                                                        <ul class="prin_ul_li_one"> 
                                                            <li class="prin_li_one_top">
                                                                <img src="../../assets/img/lan_nail.png" alt="">
                                                                <p class="prin_div_p">他做过的</p>
                                                            </li>
                                                            <li class="prin_li_one_bott" v-for="item in 5" :key="item">
                                                                <p></p>
                                                            </li>
                                                            <div class="prin_conte_div_p">
                                                                <div :class="indexC1==0?'prin_div_span_ac':'prin_div_span_acs'" 
                                                                    v-for="(itemC1,indexC1) in item.page3.data.p1.values" :key="indexC1">
                                                                    <div class="prin_conte_div_one">
                                                                        <span>
                                                                            {{indexC1+1}}、{{itemC1.value}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="print_li_one">
                                                    <div class="orin_ul_li_div">
                                                        <ul class="prin_ul_li_one"> 
                                                            <li class="prin_li_one_top">
                                                                <img src="../../assets/img/lan_nail.png" alt="">
                                                                <p class="prin_div_p">他担心的</p>
                                                            </li>
                                                            <li class="prin_li_one_bott" v-for="item in 5" :key="item">
                                                                <p></p>
                                                            </li>
                                                            <div class="prin_conte_div_p">
                                                                <div :class="indexC2==0?'prin_div_span_ac':'prin_div_span_acs'" 
                                                                    v-for="(itemC2,indexC2) in item.page3.data.p2.values" :key="indexC2">
                                                                    <div class="prin_conte_div_one">
                                                                        <span>
                                                                            {{indexC2+1}}、{{itemC2.value}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="fl p_kehu_ul_li">
                                                <div class="print_li_two prin_borderTop">
                                                    <div class="orin_ul_li_div">
                                                        <ul class="prin_ul_li_one"> 
                                                            <li class="prin_li_one_top">
                                                                <img src="../../assets/img/huang_nail.png" alt="">
                                                                <p class="prin_div_p">我想到的</p>
                                                            </li>
                                                            <li class="prin_li_one_bott prin_li_one_botts" v-for="item in 5" :key="item">
                                                                <p></p>
                                                            </li>
                                                            <div class="prin_conte_div_p">
                                                                <div class="prin_div_span_ac" 
                                                                    v-for="(itemC3,indexC3) in item.page3.data.p3.values" :key="indexC3">
                                                                    <div class="prin_conte_div_one">
                                                                        <span>
                                                                            {{indexC3+1}}、{{itemC3.value}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="print_li_two">
                                                    <div class="orin_ul_li_div">
                                                        <ul class="prin_ul_li_one"> 
                                                            <li class="prin_li_one_top">
                                                                <img src="../../assets/img/huang_nail.png" alt="">
                                                                <p class="prin_div_p">我听见的</p>
                                                            </li>
                                                            <li class="prin_li_one_bott prin_li_one_botts" v-for="item in 5" :key="item">
                                                                <p></p>
                                                            </li>
                                                            <div class="prin_conte_div_p">
                                                                <div class="prin_div_span_ac" 
                                                                    v-for="(itemC4,indexC4) in item.page3.data.p4.values" :key="indexC4">
                                                                    <div class="prin_conte_div_one">
                                                                        <span>
                                                                            {{indexC4+1}}、{{itemC4.value}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="print_li_two prin_borderBottom">
                                                    <div class="orin_ul_li_div">
                                                        <ul class="prin_ul_li_one"> 
                                                            <li class="prin_li_one_top">
                                                                <img src="../../assets/img/huang_nail.png" alt="">
                                                                <p class="prin_div_p">我看见的</p>
                                                            </li>
                                                            <li class="prin_li_one_bott prin_li_one_botts" v-for="item in 5" :key="item">
                                                                <p></p>
                                                            </li>
                                                            <div class="prin_conte_div_p">
                                                                <div class="prin_div_span_ac" 
                                                                    v-for="(itemC5,indexC5) in item.page3.data.p5.values" :key="indexC5">
                                                                    <div class="prin_conte_div_one">
                                                                        <span>
                                                                            {{indexC5+1}}、{{itemC5.value}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="fl p_kehu_ul_li">
                                                <div class="print_li_one marginB30">
                                                    <div class="orin_ul_li_div">
                                                        <ul class="prin_ul_li_one"> 
                                                            <li class="prin_li_one_top">
                                                                <img src="../../assets/img/lan_nail.png" alt="">
                                                                <p class="prin_div_p">他兴奋的</p>
                                                            </li>
                                                            <li class="prin_li_one_bott" v-for="item in 5" :key="item">
                                                                <p></p>
                                                            </li>
                                                            <div class="prin_conte_div_p">
                                                                <div :class="indexC6==0?'prin_div_span_ac':'prin_div_span_acs'" 
                                                                    v-for="(itemC6,indexC6) in item.page3.data.p6.values" :key="indexC6">
                                                                    <div class="prin_conte_div_one">
                                                                        <span>
                                                                            {{indexC6+1}}、{{itemC6.value}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="print_li_one">
                                                    <div class="orin_ul_li_div">
                                                        <ul class="prin_ul_li_one"> 
                                                            <li class="prin_li_one_top">
                                                                <img src="../../assets/img/lan_nail.png" alt="">
                                                                <p class="prin_div_p">他说过的</p>
                                                            </li>
                                                            <li class="prin_li_one_bott" v-for="item in 5" :key="item">
                                                                <p></p>
                                                            </li>
                                                            <div class="prin_conte_div_p">
                                                                <div :class="indexC7==0?'prin_div_span_ac':'prin_div_span_acs'" 
                                                                    v-for="(itemC7,indexC7) in item.page3.data.p7.values" :key="indexC7">
                                                                    <div class="prin_conte_div_one">
                                                                        <span>
                                                                            {{indexC7+1}}、{{itemC7.value}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p class="print_allPs"></p>
                                <div class="print_main_div print_one">
                                    <div class="print_DCGJ">
                                        <p class="print_allP">底层构建</p>
                                        <div class="print_contents">
                                            <ul class="print_dc_ul clearfix">
                                                <li class="print_dc_left fl">
                                                    <div class="tit_div">
                                                        <i class="iconfonts icons-yonghu"></i>
                                                        <p>用户</p>
                                                    </div>
                                                    <div class="prin_left_div">
                                                        <div class="print_ten_div">
                                                            <p class="print_l_div_ps" v-for="(itemD1,indexD1) in item.page4.data.p1.values" :key="indexD1">
                                                                <span>{{indexD1+1}}、{{itemD1.value}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="prin_lcon_div">
                                                        <p class="print_l_div_p" v-for="(itemD3,indexD3) in item.page4.data.p3.values" :key="indexD3">
                                                            <span>{{indexD3+1}}、{{itemD3.value}}</span>
                                                        </p>
                                                    </div>
                                                </li>
                                                <li class="print_dc_right fl">
                                                    <div class="tit_div">
                                                        <i class="iconfonts icons-uf_customer"></i>
                                                        <p>客户</p>
                                                    </div>
                                                    <div class="prin_left_div">
                                                        <div class="print_nine_div">
                                                            <p class="print_l_div_pss" v-for="(itemD2,indexD2) in item.page4.data.p2.values" :key="indexD2">
                                                                <span>{{indexD2+1}}、{{itemD2.value}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        <div class="print_input">
                                            <span class="print_input_span">主营的一个产品（或服务）是：{{item.page4.data.project.name}}</span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="print_allPs"></p>
                                <div class="print_main_div print_one">
                                    <div class="print_CTMS">
                                        <p class="print_allP">草图描述</p>
                                        <ul class="print_CT_ul clearfix">
                                            <li v-for="(itemE1,indexE1) in item.page5.data.p1.values" :key="indexE1" class="fl">
                                                <el-image :src="itemE1.value" fit="scale-down" ></el-image>
                                                <div class="pring_div_soan">{{itemE1.userName}}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p class="print_allPs"></p>
                                <div class="print_main_div print_one">
                                    <div class="print_MXZZ">
                                        <p class="print_allP">模型制作</p>
                                        <ul class="print_m_ul clearfix">
                                            <li class="print_m_ul_li fl" v-for="(itemF1,indexF1) in item.page6.data" :key="indexF1">
                                                <p class="print_li_title"> <span><i class="iconfonts icons-xinghaoguige"></i> </span> 产品规格{{indexF1+1}}</p>
                                                <div class="print_li_content">
                                                    <div class="fl print_li_div_img">
                                                        <el-carousel>
                                                            <el-carousel-item v-for="(itemF1s,indexF1s) in itemF1.img" :key="indexF1s">
                                                                <img :src="itemF1s" alt="">
                                                            </el-carousel-item>
                                                        </el-carousel>
                                                    </div>
                                                    <div class="fl print_div_cont">
                                                        <p class="print_div_name">{{itemF1.name}}</p>
                                                        <p class="print_div_jianj">
                                                            <span>{{itemF1.desc}}</span>
                                                        </p>
                                                        <p class="print_p_tit" v-for="(itemF1ss,indexF1ss) in itemF1.tag" :key="indexF1ss">
                                                            <strong>{{itemF1ss.name}}</strong>：
                                                            <span>{{itemF1ss.val}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p class="print_allPs"></p>
                                <div class="print_main_div print_one">
                                    <div class="print_KXYH">
                                        <p class="print_allP">可行优化</p>
                                        <div class="print_KX_div">
                                            <div class="print_kxyh_left">
                                                <p class="prin_lable_p">排行榜</p>
                                                <ul class="left_uls">
                                                    <li>
                                                        <div class="li_one hui">
                                                            排名
                                                        </div>
                                                        <div class="li_two lan" style="color:#fff">
                                                            <span>最赞赏的</span>
                                                        </div>
                                                        <div class="li_two hong" style="color:#fff">
                                                            <span>票数</span>
                                                        </div>
                                                    </li>
                                                    <li v-for="(itemG1,indexG1) in item.page8.data.p1.value" :key="indexG1">
                                                        <div class="li_one qianhui">
                                                            <span>{{indexG1+1}}</span>
                                                        </div>
                                                        <div class="li_two qianlan">
                                                            <span>{{itemG1.name}}</span>
                                                        </div>
                                                        <div class="li_two qianhong">
                                                            <span>{{itemG1.score}}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="print_kxyh_right">
                                                <div class="print_keh_ul clearfix">
                                                    <div class="fl print_ker_div">
                                                        <p class="prin_title_divp">我们：最明确的</p>
                                                        <div class="prin_content_dov">
                                                            <div class="prin_content_daa">
                                                                <p v-for="(itemG2,indexG2) in item.page8.data.p4.users" :key="indexG2">
                                                                    <span>{{indexG2 + 1 }}</span>、
                                                                    {{itemG2.zmqd}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="fr print_ker_div">
                                                        <p class="prin_title_divp">我们：最新想到的</p>
                                                        <div class="prin_content_dov">
                                                            <div class="prin_content_daa">
                                                                <p v-for="(itemG3,indexG3) in item.page8.data.p4.users" :key="indexG3">
                                                                    <span>{{indexG3 + 1 }}</span>、
                                                                    {{itemG3.zxxd}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 票数统计 -->
                                                <div class="print_righttop">
                                                    <p class="clearfix right_t_p">
                                                        <span class="fl right_t_one">
                                                            <i class="iconfonts icons-gupiao"></i>
                                                        </span>
                                                        <span class="fl right_text">
                                                            票数统计
                                                        </span>
                                                    </p>
                                                    <div class="zhuz_tus">
                                                        <img src="" :id="'mainss'+index" style="width:100%;height:100%;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="print_allPs"></p>
                                <div class="print_main_div print_one">
                                    <div class="print_ZJCX">
                                        <div class="p_data_div_ls fl">
                                            <p class="print_allP">最简呈现</p>
                                            <div class="caotu_p_divs">
                                                <p class="p_le_ps">草图</p>
                                                <div class="p_le_imgs">
                                                    <img :src="item.page9.data.p1.value[0].value" alt="">
                                                </div>
                                            </div>
                                            <div class="caotu_p_divss">
                                                <p class="p_le_ps">推演测试</p>
                                                <div class="p_le_imgss_b">
                                                    <img :src="item.page9.data.p8.value" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p_data_div_rs fr">
                                            <el-form :model="courForm"  class="demo-ruleForm" label-width="120px" size="small" @submit.native.prevent>
                                                <el-form-item label="现状：" prop="name">
                                                    <el-col :span="24">
                                                        <div class="p_col_divs">
                                                            {{item.page9.data.p2.value}}
                                                        </div>
                                                    </el-col>
                                                </el-form-item>
                                                <el-form-item label="问题：" prop="name">
                                                    <el-col :span="24">
                                                        <div class="p_col_divs">
                                                            {{item.page9.data.p3.value}}
                                                        </div>
                                                    </el-col>
                                                </el-form-item>
                                                <el-form-item label="创意（想法）：" prop="name">
                                                    <el-col :span="24">
                                                        <div class="p_col_divs">
                                                            {{item.page9.data.p4.value}}
                                                        </div>
                                                    </el-col>
                                                </el-form-item>
                                                <el-form-item label="创新（想法）：" prop="name">
                                                    <el-col :span="24">
                                                        <div class="p_col_divs">
                                                            <div class="p_zui_three_ul">
                                                                <div class="p_zui_three_ul_li" v-for="(itemH1,indexH1) in item.page9.data.p5.value" :key="indexH1">
                                                                    <div class="p_zui_three_div clearfix">   
                                                                        <span class="fl">热度为{{itemH1.level}}颗星的：</span>
                                                                        <span class="fl">
                                                                            <el-rate
                                                                                v-model="itemH1.level"
                                                                                disabled
                                                                                :colors="['#ff9900','#ff9900','#ff9900']">
                                                                            </el-rate>
                                                                        </span>
                                                                    </div>
                                                                    <div class="p_icon_ul clearfix">
                                                                        <div class="p_zui_ul_li_item fl" v-for="(itemH2,indexH2) in itemH1.values" :key="indexH2">
                                                                            {{itemH2.name}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                </el-form-item>
                                                <el-form-item label="方案（适应）：" prop="name">
                                                    <el-col :span="24">
                                                        <div class="p_col_divs">
                                                            {{item.page9.data.p6.value}}
                                                        </div>
                                                    </el-col>
                                                </el-form-item>
                                                <p style="font-size:14px;line-height:40px;padding-left:11px;font-weight:400;color:#333">行动（分工）：</p>
                                                <el-col :span="8">
                                                    <el-form-item :label="item.page9.data.p7.value.role1.title" label-width="80px" prop="name">
                                                        <el-input v-model="item.page9.data.p7.value.role1.name" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item :label="item.page9.data.p7.value.role2.title" label-width="80px" prop="name">
                                                        <el-input v-model="item.page9.data.p7.value.role2.name" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item :label="item.page9.data.p7.value.role3.title" label-width="80px" prop="name">
                                                        <el-input v-model="item.page9.data.p7.value.role3.name" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item :label="item.page9.data.p7.value.role4.title" label-width="80px" prop="name">
                                                        <el-input v-model="item.page9.data.p7.value.role4.name" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item :label="item.page9.data.p7.value.role5.title" label-width="80px" prop="name">
                                                        <el-input v-model="item.page9.data.p7.value.role5.name" disabled></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import {  Loading } from 'element-ui';
import $ from 'jquery' 

// 在请求接口前创建loading参数
const options = {
    text: '载入中...',
    background: 'rgba(0, 0, 0, 0.8)',
    fontSize:'20px',
    spinner: 'el-icon-loading',
    lock: true,
    // target指定需要覆盖的 DOM 节点
    target: '#app'
};
let loadingInstance;
// import '@/utils/jquery-1.4.4.min.js'
// import '@/utils/jquery-migrate-1.2.1.min.js'
// import '@/utils/jquery.jqprint-0.3.js'
export default {
    data() {
        return {
            sortList:[],
            courForm:{
                p2:{},
                p3:{},
                p4:{},
                p5:{},
                p6:{},
                p7:{
                    value:{
                        role1:{
                            title:'负责人',
                            name:'普京'
                        },
                        role2:{},
                        role3:{},
                        role4:{},
                        role5:{},
                        
                    }
                }
            },
            dialogVisibleForm:false,
            dialogPrint:false,
            infoForm:{
                top:'',
                pos:'',
                score:[],
                name:'',
            },
            rules:{},
            active:-1,
            teamList:[],
            paperList:{
                s1:{},
                s2:{},
                s3:{},
                s4:{},
                s5:{},
                s6:{},
                s7:{},
                s8:{},
                s9:{},
            },
            num:0,
            countNum:0,
            form:{},
            count:{},
            ruleForm:{
                name:'',
                mins:'',
                type:1,
            },
            rules:{
                mins:[
                    { required: true, message: '请输入分钟数', trigger: 'blur' },
                    { pattern:/^\d+$/,message:'分钟数必须为正整数'}
                ],
                type:[
                    { required: true, message: '请选择状态', trigger: 'change' }
                ],

            },
            dialogVisible:false,
            timer:'',
            course:{},
            activeName:'第1组',
            pieData:[],
            barData:{
                cyxy:[],
                gnsy:[],
                kxxg:[],
                xAxis:[],
            },
            page8Bar:{
                cyxy:[],
                gnsy:[],
                kxxg:[],
                xAxis:[],
            },
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.num=this.$route.query.num
            this.$emit('func', this.calId);
            this.axios.Teacreatedetail({
                token:this.$store.state.token,
                id:this.calId
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data.step
                    this.course= res.data.data.course
                    this.teamList = res.data.data.team
                    if(this.num==1){
                        this.$store.dispatch("tem_id",this.teamList[0].id)
                        this.teamBoxs()
                    }else{
                        this.teamBoxs()
                    }
                    this.form.steps.map(item=>{
                        if(item.name==this.form.name){
                            this.active = item.id
                        }else if(this.form.id==99){
                            this.active = 9
                        }
                    })
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
            // 获取倒计时
            this.axios.coursectdGet({
                token:this.$store.state.token,
                id:this.calId
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' && res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        if(this.count.state==1){
                            this.countDown()
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer =  m +':'+ s
                        }
                    }else{
                        this.countNum = 2
                    }
                }else{
                    this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        statistics(){
            this.$router.push({name:'Coursestatistics',query:{calId:this.calId}})
        },
        aaaaa(item){
            if(item.step==1){
                this.$router.push({name:'CognitiveRZSL',query:{calId:this.calId}})
            }else if(item.step==2){
                this.$router.push({name:'CognitiveZLSJ',query:{calId:this.calId}})
            }else if(item.step==3){
                this.$router.push({name:'CognitiveCGJD',query:{calId:this.calId}})
            }else if(item.step==4){
                this.$router.push({name:'CognitiveDCGJ',query:{calId:this.calId}})
            }else if(item.step==5){
                this.$router.push({name:'CognitiveCTMS',query:{calId:this.calId}})
            }else if(item.step==6){
                this.$router.push({name:'CognitiveMXZZ',query:{calId:this.calId}})
            }else if(item.step==7){
                this.$router.push({name:'CognitiveTYCS',query:{calId:this.calId}})
            }else if(item.step==8){
                this.$router.push({name:'CognitiveKXYH',query:{calId:this.calId}})
            }else if(item.step==9){
                this.$router.push({name:'CognitiveZJCX',query:{calId:this.calId}})
            }
        },
        // 导出预览
        Allexport(){
            this.axios.projectsexport({
                token:this.$store.state.token,
                id:this.calId
            }).then(res=>{
                this.courForm = res.data.data[3].page0.s9
                this.sortList = res.data.data
                this.pieData = this.sortList[0].page0.pie
                this.barData = this.sortList[0].page0.bar
                this.page8Bar = this.sortList[0].page8.data.p2.value
                this.dialogPrint = true
                this.piePhoto(0)
            }).catch(err=>{

            })
        },
        piePhoto(val){
            this.$nextTick(()=>{
                this.mycharts(val)
                this.Bmychart(val)
                this.mychartKXYH(val)
            })
        },
        handleClick(tab, event){
            const arrL = this.sortList.filter((item,index)=>index == tab.index)
            this.pieData = arrL[0].page0.pie
            this.barData = arrL[0].page0.bar
            this.page8Bar = arrL[0].page8.data.p2.value
            this.piePhoto(tab.index)
        },
        // 饼状图
        Bmychart(val){
            var chartDom = document.getElementById('main'+val)
            var mcChart = echarts.init(chartDom);
            // this.myChart2 = mcChart
            let option = {
                tooltip: {
                    trigger: 'item',
                    formatter:'{a} <br/>{b} : {c}票 ({d}%)',
                    axisPointer: {
                        type: 'shadow',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                    position: function(pt) { //提示框的位置
                        return [pt[0], 30];
                    }
                },
                legend:{
                    orient: 'vertical',
                    top:'40%',
                    left:'70%',
                },
                animation:false,
                series:[
                    {
                        name:'票数统计',
                        type:'pie',
                        radius:'50%',
                        center:['40%','50%'],
                        data:this.pieData,
                        color: ['#55AAFF','#FFC000','#26D096',],
                        itemStyle:{
                            emphasis:{
                                shadowBlur:10,
                                shadowOffserX:0,
                                shadowColor:'#333'
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false   // show设置线是否显示，默认为true，可选值：true ¦ false
                            }
                        },
                        label: {
                            normal: {
                                position: 'inner',  // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                                // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                                // {a}指series.name  {b}指series.data的name
                                // {c}指series.data的value  {d}%指这一部分占总数的百分比
                                formatter: '{c}'
                            }
                        }
                    }
                ],

            }
            mcChart.setOption(option)
            var imgSrc = mcChart.getDataURL()
            $('#main'+val)
            .attr('src',imgSrc)
            .show()

        },
        // 柱状图
        mycharts(val){
            var chartDom1 = document.getElementById('mains'+val)
            var mcCharts = echarts.init(chartDom1);
              let options = {
                    color:['#54AAD8','#FFD25B','#FF805B'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                            position: function(pt) { //提示框的位置
                            return [pt[0], 30];
                        }
                    },
                     grid: { //图表和父盒子之间的距离
                        show: true,
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top:'17%',
                        containLabel: true
                    },
                    legend: {//图例
                        top:'5%',
                        data: [
                            {
                                name:'创意新颖',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '功能实用',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '可行性高',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                        ]
                    },
                    xAxis:{ //x轴
                            type: 'category',
                            // boundaryGap: false,
                            data: this.barData.xAxis,
                            axisLabel: { 
                                interval:0,
                                textStyle: {
                                color: '#333',
                                    fontSize :10
                                },
                                margin:8
                            },
                            axisLine:{
                                show :true,
                                // symbol: ['none', 'arrow'],
                                lineStyle:{
                                    color:'rgb(2,121,253)',
                                }
                            },
                            axisTick:{
                                show :false,
                            },
                            splitLine:{
                                show:false,
                            },
                        },
                    yAxis:{
                            type: 'value',
                            axisLabel: { //x轴的坐标文字
                                show: true,
                                
                                textStyle: {
                                    color: '#333' //文字的颜色
                                },
                            },
                            max: function (value) {
                                return Math.ceil(value.max + value.max * 0.1);
                            },
                            axisLine:{
                                show :true,
                                lineStyle:{
                                    color:'rgb(2,121,253)'
                                }
                            },
                            axisTick:{
                                show :false,
                            },
                            splitLine:{  //坐标在grid区域的分割线
                            　 lineStyle: { //设置分割线的样式(图表横线颜色)
                                    color: ['#153a8a']
                                }
                            }
                    },
                    animation:false,
                    series: [
                        {
                            name: '创意新颖',
                            type: 'bar', //柱状图
                            data: this.barData.cyxy,
                            barWidth :'10%', //柱状体的宽度
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14,
                                        }
                                    }
                                }
                            }
                        },
                        {
                            name: '功能实用',
                            type: 'bar',
                            data: this.barData.gnsy,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14,
                                        }
                                    }
                                }
                            }  
                        },
                        {
                            name: '可行性高',
                            type: 'bar',
                            data: this.barData.kxxg,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14,
                                        }
                                    }
                                }
                            }
                        },
                    
                    ]
                }
            mcCharts.setOption(options)
            var imgSrcs = mcCharts.getDataURL()
            $('#mains'+val)
            .attr('src',imgSrcs)
            .show()
        },
        // 柱状图
        mychartKXYH(val){
            var chartDomKXYH = document.getElementById('mainss'+val)
            var KmcCharts = echarts.init(chartDomKXYH);
              let options = {
                    color:['#54AAD8','#FFD25B','#FF805B'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                            position: function(pt) { //提示框的位置
                            return [pt[0], 30];
                        }
                    },
                     grid: { //图表和父盒子之间的距离
                        show: true,
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top:'17%',
                        containLabel: true
                    },
                    legend: {//图例
                        top:'5%',
                        data: [
                            {
                                name:'创意新颖',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '功能实用',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '可行性高',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                        ]
                    },
                    xAxis:{ //x轴
                            type: 'category',
                            // boundaryGap: false,
                            data: this.page8Bar.xAxis,
                            axisLabel: { 
                                interval:0,
                                textStyle: {
                                color: '#333',
                                    fontSize :10
                                },
                                margin:8
                            },
                            axisLine:{
                                show :true,
                                // symbol: ['none', 'arrow'],
                                lineStyle:{
                                    color:'rgb(2,121,253)',
                                }
                            },
                            axisTick:{
                                show :false,
                            },
                            splitLine:{
                                show:false,
                            },
                        },
                    yAxis:{
                            type: 'value',
                            axisLabel: { //x轴的坐标文字
                                show: true,
                                
                                textStyle: {
                                    color: '#333' //文字的颜色
                                },
                            },
                            max: function (value) {
                                return Math.ceil(value.max + value.max * 0.1);
                            },
                            axisLine:{
                                show :true,
                                lineStyle:{
                                    color:'rgb(2,121,253)'
                                }
                            },
                            axisTick:{
                                show :false,
                            },
                            splitLine:{  //坐标在grid区域的分割线
                            　 lineStyle: { //设置分割线的样式(图表横线颜色)
                                    color: ['#153a8a']
                                }
                            }
                    },
                    animation:false,
                    series: [
                        {
                            name: '创意新颖',
                            type: 'bar', //柱状图
                            data: this.page8Bar.cyxy,
                            barWidth :'10%', //柱状体的宽度
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14,
                                        }
                                    }
                                }
                            }
                        },
                        {
                            name: '功能实用',
                            type: 'bar',
                            data: this.page8Bar.gnsy,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14,
                                        }
                                    }
                                }
                            }  
                        },
                        {
                            name: '可行性高',
                            type: 'bar',
                            data: this.page8Bar.kxxg,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14,
                                        }
                                    }
                                }
                            }
                        },
                    
                    ]
                }
            KmcCharts.setOption(options)
            var imgSrcs = KmcCharts.getDataURL()
            $('#mainss'+val)
            .attr('src',imgSrcs)
            .show()
        },
        subxits(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var arr = []
                    this.infoForm.score.map(item=>{
                        arr.push(Object.assign({},{id:item.id,val:item.val}))
                    })
                    this.axios.Teacreateend({
                        token:this.$store.state.token,
                        id:this.calId,
                        top:this.infoForm.top,
                        pos:this.infoForm.pos,
                        score:arr
                    }).then(res=>{
                        if(res.data.code==0){
                            this.dialogVisibleForm = false
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //获取小组作业纸列表
        teamBox(item){
            this.$store.dispatch("tem_id",item.id)
            this.teamBoxs()
        },
        teamBoxs(){
            this.axios.Teacreateteam({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.paperList = res.data.data
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 新建倒计时
        addPro(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.time = 0
                    this.ruleForm.token = this.$store.state.token
                    this.ruleForm.id = this.calId
                    this.axios.coursecctdAdd(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==0){
                            this.$message.success('新建成功')
                            clearTimeout(this.timeout)
                            this.timeout = null
                            this.dialogVisible = false
                            this.$refs[formName].resetFields();
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        // 输入框回车事件
        enterInput(e){
            if (e.which == 13 || e.charCode == 13 || e.keyCode == 13) {
                this.addPro('ruleForm');
            }
        },
        //开始倒计时
        startdown(){
            this.axios.ctdStart({
                token:this.$store.state.token,
                id:this.calId
            }).then(res=>{
                if(res.data.code==0){
                    this.getapp()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer =  m +':'+ s
                this.time--
                this.timeout = setTimeout(this.countDown,1000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
        // 开课
        classes(){
            loadingInstance = Loading.service(options);
            this.axios.Teacreatestart({
                token:this.$store.state.token,
                id:this.calId
            }).then(res=>{
                if(res.data.code==0){
                    loadingInstance.close();
                    this.$message.success('已开课')
                    this.getapp()
                }else{
                    this.$message.error(res.data.message)
                    loadingInstance.close();
                }
            }).catch(err=>{

            })
        },
        //下一步
        nextes(){
            this.$confirm('是否开启下一步', '确定开启', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                loadingInstance = Loading.service(options);
                this.axios.Teacreatesstep({
                    token:this.$store.state.token,
                    id:this.calId
                }).then(res=>{
                    if(res.data.code==0){
                        if(res.data.data.id==99){
                            this.session()
                        }else{
                            this.getapp()
                        }
                    }else{
                        this.$message.error(res.data.message)
                    }
                      loadingInstance.close();
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        //结课
        session(){
            this.infoForm.score = this.teamList
            this.dialogVisibleForm = true
        },
        //撤回可行优化
        withdraw(){
            this.$confirm('此操作会导致可行优化投票数据清空，是否撤回？', '确定撤回', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.coursereset7({
                    token:this.$store.state.token,
                    id:this.calId
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                        this.$message.success("已撤回")
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消撤回'
                });          
            });
        },
        back(){
            this.$router.push({name:'Courseinformation'})
        },
        handleClose(done){

        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.dialogVisibleForm = false
            this.$refs[formName].resetFields();
        },
        closeis(){
            this.dialogPrint = false
            this.activeName = '第1组'
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/teacher/Coursedetails.css';
</style>
<style>
.course_popup .el-tabs--border-card>.el-tabs__content{
    padding: 0;
}
.course_popup .el-tabs__content{
    overflow: inherit;
}
.print_li_div_img .el-carousel{
    width: 100%;
    height: 100%;
}
.print_li_div_img .el-carousel__container{
    height: 100%;
}
.print_li_div_img .el-carousel__arrow{
    display: none!important;
}
.print_li_div_img .el-carousel__arrow:hover{
    display: none!important;
}
.print_li_div_img .el-carousel__indicators{
    display: none;
}
.print_CT_ul .el-image{
  width: 100%;
  height: calc(100% - 30px);
}
.bor_div_top .el-progress{
    position: absolute;
    transform: rotate(180deg);
}
.bor_div_top .el-progress-circle{
    width: 50px!important;
    height: 50px!important;
}
.course_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.dia_print .el-dialog__header{
    padding: 0;
}
.dia_print .el-dialog{
    margin-top: 5vh!important;
    max-height: 90vh;
}
.dia_print .el-button{
    width: auto!important;
}
.course_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.DP_teace .el-input__inner{
    width: 215px;
}
.aaa_form{
    margin-right: 0!important;
    margin-bottom: 0!important;
}
.course_popup .el-dialog__body{
    padding: 20px 30px 10px;
}
.course_popup .el-form-item__label{
    font-size: 14px;
    font-weight: bold;
    color: #333;
}
.course_popup .el-radio-group{
    margin-left: 15px;
}
.course_popup .el-form-item{
    margin-bottom: 18px;
}
.course_popup .el-dialog__footer{
    text-align: center;
}
.course_popup .el-button{
    width: 98px;
    font-size: 14px;
}
.course_popup .el-button--success{
    background: #05D1C6;
    border-color: #05D1C6;
}
.course_popup .el-range-editor.el-input__inner{
    width: 100%;
}
.course_popup .el-select{
    width: 100%;
}

.p_data_div_r .el-form-item__label{
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px 0 0;
}
.p_data_div_r .el-form-item--mini.el-form-item, .p_data_div_r .el-form-item--small.el-form-item{
    margin-bottom: 10px;
}
.p_data_div_r .el-textarea.is-disabled .el-textarea__inner{
    color: #333;
    background: #fff;
    cursor: default;
}
.p_data_div_r .el-input.is-disabled .el-input__inner{
    color: #333;
    background: #fff;
    cursor: default;
}
.p_data_div_rs .el-form-item__label{
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px 0 0;
}
.p_data_div_rs .el-form-item--mini.el-form-item, .p_data_div_rs .el-form-item--small.el-form-item{
    margin-bottom: 10px;
}
.p_data_div_rs .el-textarea.is-disabled .el-textarea__inner{
    color: #333;
    background: #fff;
    cursor: default;
}
.p_data_div_rs .el-input.is-disabled .el-input__inner{
    color: #333;
    background: #fff;
    cursor: default;
}
.p_zui_three_div .el-rate{
    height: 17px;
}
</style>